import store from "..";
import { Module, getModule } from "vuex-module-decorators";
import { Activity } from "@pasabi/ui-components";
import { ListingPageStore } from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";

@Module({
  name: "ActivitiesList",
  store: store,
  dynamic: true,
  namespaced: true,
})
class ActivitiesList extends ListingPageStore<Activity> {
  _apiPath = "/activities";

  get api() {
    return $api;
  }
}

export const ActivitiesListStore = getModule(ActivitiesList);
