interface IObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

export const mergeDeep = (target: IObject, source: IObject) => {
  const output: IObject = Object.assign({}, target);

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key: string) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

export const dateDaysDiff = (startDate: Date, endDate: Date = new Date()) => {
  const diffInMs = endDate.getTime() - startDate.getTime();
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // convert ms to days
};

export const generateRandomString = () =>
  (Math.random() + 1).toString(36).substring(2);
