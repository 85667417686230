import AcceptInvite from "@/views/auth/AcceptInvite.vue";
import ActivateAccount from "@/views/auth/ActivateAccount.vue";
import ActivityList from "@/views/activities/ActivityList.vue";
import Cluster from "@/views/cluster/Cluster.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import GroupSuspectDetails from "@/views/groupSuspects/GroupSuspectDetails.vue";
import GroupSuspectPostsTab from "@/views/groupSuspects/components/GroupSuspectPostsTab.vue";
import LayoutSignedIn from "@/views/layouts/LayoutSignedIn.vue";
import LayoutSignedOut from "@/views/layouts/LayoutSignedOut.vue";
import PassThrough from "@/views/PassThrough.vue";
import PasswordReminder from "@/views/auth/PasswordReminder.vue";
import PasswordReset from "@/views/auth/PasswordReset.vue";
import PostCommentsTab from "@/views/posts/components/PostCommentsTab.vue";
import PostDetails from "@/views/posts/PostDetails.vue";
import PostDetailsTab from "@/views/posts/components/PostDetailsTab.vue";
import PostHashtagsTab from "@/views/posts/components/PostHashtagsTab.vue";
import PostHistoryTab from "@/views/posts/components/PostHistoryTab.vue";
import PostNotesTab from "@/views/posts/components/PostNotesTab.vue";
import PostsList from "@/views/posts/PostsList.vue";
import SignIn from "@/views/auth/SignIn.vue";
import SignOut from "@/views/auth/SignOut.vue";
import SuspectDetails from "@/views/suspects/SuspectDetails.vue";
import SuspectHistoryTab from "@/views/suspects/components/SuspectHistoryTab.vue";
import SuspectNotesTab from "@/views/suspects/components/SuspectNotesTab.vue";
import SuspectPostsTab from "@/views/suspects/components/SuspectPostsTab.vue";
import SuspectProfileTab from "@/views/suspects/components/SuspectProfileTab.vue";
import SuspectTagsTab from "@/views/suspects/components/SuspectKeywordsTab.vue";
import SuspectsList from "@/views/suspects/SuspectsList.vue";
import SyndicateDetails from "@/views/syndicates/SyndicateDetails.vue";
import SyndicateGraph from "@/views/syndicates/components/SyndicateGraph.vue";
import SyndicateNotesTab from "@/views/syndicates/components/SyndicateNotesTab.vue";
import SyndicatePostsTab from "@/views/syndicates/components/SyndicatePostsTab.vue";
import SyndicateSuspectsTab from "@/views/syndicates/components/SyndicateSuspectsTab.vue";
import Syndicates from "@/views/syndicates/Syndicates.vue";
import SyndicatesList from "@/views/syndicates/SyndicatesList.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import i18n from "@/i18n";
import { AuthStore } from "@/store/modules/Auth";
import { PostDetailsStore } from "../store/modules/PostDetails";
import { SuspectDetailsStore } from "../store/modules/SuspectDetails";
import { SyndicateDetailsStore } from "../store/modules/SyndicateDetails";
import { GroupSuspectDetailsStore } from "../store/modules/GroupSuspectDetails";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/auth",
    component: LayoutSignedOut,
    meta: {
      auth: false,
    },
    children: [
      {
        path: "sign-in",
        name: "signin",
        component: SignIn,
        meta: {
          auth: false,
        },
      },
      {
        path: "sign-out",
        name: "signout",
        component: SignOut,
        meta: {
          auth: false,
        },
      },
      {
        path: "reminder",
        name: "reminder",
        component: PasswordReminder,
        meta: {
          auth: false,
        },
      },
      {
        path: "reset",
        name: "reset",
        component: PasswordReset,
        meta: {
          auth: false,
        },
      },
      {
        path: "accept",
        name: "accept",
        component: AcceptInvite,
        meta: {
          auth: false,
        },
      },
      {
        path: "activate",
        name: "activateAccount",
        component: ActivateAccount,
        meta: {
          auth: false,
        },
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    component: LayoutSignedIn,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "w/:workspaceSlug",
        component: PassThrough,
        name: "WorkspaceHome",
        redirect: { name: "Dashboard" },
        meta: {
          workspaceContext: true,
        },
        children: [
          {
            path: "",
            redirect: { name: "Dashboard" },
          },
          {
            path: "dashboard",
            name: "Dashboard",
            component: Dashboard,
          },
          {
            path: "suspects",
            component: PassThrough,
            name: "Suspects",
            redirect: { name: "SuspectsList" },
            meta: {
              breadcrumb: i18n.t("counterfeit.suspects.title"),
            },
            children: [
              {
                path: "",
                name: "SuspectsList",
                component: SuspectsList,
              },
              {
                path: "details/:id",
                name: "SuspectDetails",
                redirect: { name: "SuspectProfileTab" },
                component: SuspectDetails,
                meta: {
                  breadcrumb: function breadcrumb() {
                    return SuspectDetailsStore.suspectName(
                      this.$route.params.id
                    );
                  },
                },
                children: [
                  {
                    path: "",
                    name: "SuspectProfileTab",
                    component: SuspectProfileTab,
                    meta: {
                      breadcrumb: i18n.t("suspects.profile.tabs.profile"),
                    },
                  },
                  {
                    path: "posts/:section?",
                    name: "SuspectPostsTab",
                    component: SuspectPostsTab,
                    meta: {
                      breadcrumb: i18n.t("suspects.profile.tabs.posts"),
                    },
                  },
                  {
                    path: "keywords",
                    name: "SuspectKeywordsTab",
                    component: SuspectTagsTab,
                    meta: {
                      breadcrumb: i18n.t("suspects.profile.tabs.keywords"),
                    },
                  },
                  {
                    path: "notes",
                    name: "SuspectNotesTab",
                    component: SuspectNotesTab,
                    meta: {
                      breadcrumb: i18n.t("suspects.profile.tabs.notes"),
                    },
                  },
                  {
                    path: "history",
                    name: "SuspectHistoryTab",
                    component: SuspectHistoryTab,
                    meta: {
                      breadcrumb: i18n.t("suspects.profile.tabs.history"),
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "posts",
            name: "Posts",
            component: PassThrough,
            redirect: { name: "PostsList" },
            meta: {
              breadcrumb: i18n.t("counterfeit.posts.title"),
            },
            children: [
              {
                path: "",
                name: "PostsList",
                component: PostsList,
              },
              {
                path: "details/:id",
                name: "PostDetails",
                redirect: { name: "PostDetailsTab" },
                component: PostDetails,
                meta: {
                  breadcrumb: function breadcrumb() {
                    return PostDetailsStore.postName(this.$route.params.id);
                  },
                },

                children: [
                  {
                    path: "",
                    name: "PostDetailsTab",
                    component: PostDetailsTab,
                    meta: {
                      breadcrumb: i18n.t("posts.profile.tabs.details"),
                    },
                  },
                  {
                    path: "hashtags",
                    name: "PostHashtagsTab",
                    component: PostHashtagsTab,
                    meta: {
                      breadcrumb: i18n.t("posts.profile.tabs.hashtags"),
                    },
                  },
                  {
                    path: "comments",
                    name: "PostCommentsTab",
                    component: PostCommentsTab,
                    meta: {
                      breadcrumb: i18n.t("posts.profile.tabs.comments"),
                    },
                  },
                  {
                    path: "notes",
                    name: "PostNotesTab",
                    component: PostNotesTab,
                    meta: {
                      breadcrumb: i18n.t("posts.profile.tabs.notes"),
                    },
                  },
                  {
                    path: "history",
                    name: "PostHistoryTab",
                    component: PostHistoryTab,
                    meta: {
                      breadcrumb: i18n.t("posts.profile.tabs.history"),
                    },
                  },
                ],
              },
            ],
          },

          {
            path: "activity",
            name: "Activity",
            component: ActivityList,
          },
        ],
      },
      {
        path: "g/:groupId",
        name: "GroupHome",
        component: PassThrough,
        meta: {
          groupContext: true,
        },
        redirect: { name: "Syndicates" },
        children: [
          {
            path: "syndicates",
            component: Syndicates,
            name: "Syndicates",
            redirect: { name: "SyndicatesList" },
            meta: {
              breadcrumb: i18n.t("counterfeit.syndicates.title"),
            },
            children: [
              {
                path: "",
                name: "SyndicatesList",
                component: SyndicatesList,
              },
              {
                path: "details/:id",
                name: "SyndicateDetails",
                redirect: { name: "SyndicateSuspectsTab" },
                component: SyndicateDetails,
                meta: {
                  breadcrumb: function breadcrumb() {
                    return SyndicateDetailsStore.syndicateName(
                      this.$route.params.id
                    );
                  },
                },
                children: [
                  {
                    path: "suspects",
                    name: "SyndicateSuspectsTab",
                    component: SyndicateSuspectsTab,
                    meta: {
                      breadcrumb: i18n.t("syndicates.profile.tabs.suspects"),
                    },
                  },
                  {
                    path: "posts/:section?",
                    name: "SyndicatePostsTab",
                    component: SyndicatePostsTab,
                    meta: {
                      breadcrumb: i18n.t("syndicates.profile.tabs.posts"),
                    },
                  },
                  {
                    path: "notes",
                    name: "SyndicateNotesTab",
                    component: SyndicateNotesTab,
                    meta: {
                      breadcrumb: i18n.t("syndicates.profile.tabs.notes"),
                    },
                  },
                  {
                    path: "graph",
                    name: "SyndicateGraph",
                    component: SyndicateGraph,
                    meta: {
                      breadcrumb: i18n.t("syndicates.profile.graph.title"),
                      plainLayout: true,
                    },
                  },
                ],
              },
              {
                path: "suspects",
                component: PassThrough,
                name: "g/Suspects",
                children: [
                  {
                    path: "details/:id",
                    name: "g/SuspectDetails",
                    redirect: { name: "g/SuspectPostsTab" },
                    component: GroupSuspectDetails,
                    meta: {
                      breadcrumb: function breadcrumb() {
                        return GroupSuspectDetailsStore.suspectName(
                          this.$route.params.id
                        );
                      },
                    },
                    children: [
                      {
                        path: "posts/:section?",
                        name: "g/SuspectPostsTab",
                        component: GroupSuspectPostsTab,
                        meta: {
                          breadcrumb: i18n.t("suspects.profile.tabs.posts"),
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "cluster",
            name: "Cluster",
            component: Cluster,
          },
        ],
      },
    ],
  },
  { path: "*", redirect: { name: "Home" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log('Router, before each', to.meta?.auth, from.meta?.auth);
  if (to.matched.some((route) => route.meta?.auth === true)) {
    if (AuthStore.authenticated()) {
      next();
    } else {
      next({ name: "signin" });
    }
  } else {
    next();
  }
});

export default router;
