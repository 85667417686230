import store from "..";
import { Action, Module, getModule } from "vuex-module-decorators";
import { IPost } from "@/types/data/IPost";
import { $api } from "../../services/api/api";
import { DetailsPageStore } from "@pasabi/ui-patterns";
import { IDetailsStoreAssociations } from "@pasabi/ui-patterns/types/interfaces";
import { IQueryParams } from "@pasabi/ui-patterns";

@Module({
  name: "PostDetails",
  store: store,
  dynamic: true,
  namespaced: true,
})
class PostDetails extends DetailsPageStore<IPost> {
  _apiPath = "/posts";
  _associations: IDetailsStoreAssociations = {
    comments: {
      _apiPath: "comments",
    },
    activities: {
      _apiPath: () => `/activities`,
    },
  };

  @Action
  async fetchItem(args: {
    params: IQueryParams;
    [key: string]: unknown;
  }): Promise<void> {
    super.fetchItem({
      ...args,
      params: {
        ...args.params,
        populate: "profile",
      },
    });
  }

  get api() {
    return $api;
  }

  get postName() {
    return (postId: string) => {
      const post = this.item(postId);

      if (post) {
        return post._id;
      }
      return "";
    };
  }
}

export const PostDetailsStore = getModule(PostDetails);
