import ChannelColumn from "@/components/tables/ChannelColumn.vue";
import i18n from "@/i18n";
import { imageProxy } from "@/lib/imageProxy";
import { ISuspectSummary } from "@/types/data/ISuspect";
import { UiTagsTableColumn } from "@pasabi/ui-patterns";

export const suspectTableColumns = [
  {
    key: "suspect",
    component: {
      name: "UiProfile",
      attr: {
        inline: true,
        profile: ({
          username,
          profile_pic_url: image,
          full_name: firstname,
        }: ISuspectSummary) => {
          return {
            firstname: firstname || username,
            surname: "",
            username: `@${username}`,
            image: imageProxy(image),
          };
        },
      },
    },
  },
  {
    key: "tags",
    sort: false,
    component: {
      name: UiTagsTableColumn,
      attr: {
        tags: (suspect: ISuspectSummary) => suspect.tags || [],
      },
    },
  },
  {
    key: "platform",
    label: i18n.t("suspects.listItem.columns.platform"),
    component: {
      name: ChannelColumn,
      attr: ({ channel }: ISuspectSummary) => ({
        channel: channel,
      }),
    },
  },
  {
    key: "days_since_last_post",
    sort: true,
    label: i18n.t("suspects.listItem.columns.lastSeen"),
    format: ({ days_since_last_post: daysAgo }: ISuspectSummary) =>
      daysAgo != undefined ? i18n.tc("days-ago", daysAgo) : "n/a",
  },
  {
    key: "scores.posts_count",
    sort: true,
    label: i18n.t("suspects.listItem.columns.nPosts"),
    format: (suspect: ISuspectSummary) => suspect.scores.posts_count,
  },
  {
    key: "weighted_score_by_time",
    sort: true,
    label: i18n.t("suspects.listItem.columns.points"),
    component: {
      name: "statistics",
      attr: {
        value: ({ weighted_score_by_time: points }: ISuspectSummary) =>
          points || 0,
      },
    },
  },
];
