export interface GraphQuery {
  id: number;
  showJoinType: boolean;
  label: string;
  query: string;
}

export default [
  {
    id: 1,
    showJoinType: true,
    label: "PII multiple profiles",
    query: `
      MATCH (p:Profile)-[r:has_tel|post_has_tel]-(t:Tel)
      WITH t, count(r) as rel_cnt
      WHERE rel_cnt > 1 and size(t.no) > 6
      RETURN t as results
      UNION ALL
      MATCH (:Profile)-[r2:has_email|post_has_email]-(e:Email)
      WITH e, count(r2) as rel_cnt
      WHERE rel_cnt > 1 and e.address =~ '.*@.*'
      RETURN e as results
      UNION ALL
      MATCH (p:Profile)-[r3:has_url|post_has_url]-(u:Url)
      WITH u, count(r3) as rel_cnt
      WHERE rel_cnt > 1
      RETURN u as results
      `,
  },
] as GraphQuery[];

export const clusterQuery = (clusterId: string) => `
  MATCH (node)
  WHERE node.wcc_pii = ${clusterId}
  MATCH (node)-[r*..1]-(p)
  with collect(distinct p) as linked, collect (distinct node) as nodes, r
  RETURN nodes, linked, r
`;
