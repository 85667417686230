import amazon from "@/assets/img/marketplaces/amazon.png";
import blibli from "@/assets/img/marketplaces/blibli.png";
import carousell from "@/assets/img/marketplaces/carousell.png";
import ebay from "@/assets/img/marketplaces/ebay.png";
import facebook_marketplace from "@/assets/img/marketplaces/facebook_marketplace.png";
import farfetch from "@/assets/img/marketplaces/farfetch.png";
import google from "@/assets/img/marketplaces/google.png";
import instagram from "@/assets/img/marketplaces/instagram.png";
import lazada from "@/assets/img/marketplaces/lazada.png";
import naver from "@/assets/img/marketplaces/naver.png";
import shopee from "@/assets/img/marketplaces/shopee.png";
import tokopedia from "@/assets/img/marketplaces/tokopedia.png";

// keep comments with all available channels
// it will allow auto translations generator to find all available channels labels
// this.$t("channels.label.amazon")
// this.$t("channels.label.blibli")
// this.$t("channels.label.carousell")
// this.$t("channels.label.ebay")
// this.$t("channels.label.facebook_marketplace")
// this.$t("channels.label.farfetch")
// this.$t("channels.label.google")
// this.$t("channels.label.instagram")
// this.$t("channels.label.lazada")
// this.$t("channels.label.naver")
// this.$t("channels.label.shopee")
// this.$t("channels.label.tokopedia")

export const marketplacesIcons = {
  instagram,
  carousell,
  lazada,
  tokopedia,
  blibli,
  ebay,
  google,
  naver,
  facebook_marketplace,
  farfetch,
  shopee,
  amazon,
} as Record<string, string>;
