import i18n from "@/i18n";
import { IPostComment } from "@/types/data/IPostComment";

export const postCommentsTableColumns = [
  {
    key: "content",
    label: i18n.t("postComments.listItem.columns.content"),
    format: (comment: IPostComment) => `
      <div style="padding: 14px 0">
        <div class="txt--bold">
         ${comment.owner.username}
        </div>
        <div>
          ${comment.text}
        </div>
      </div>
    `,
  },
  {
    key: "created_at",
    sort: true,
    wrap: true,
    label: i18n.t("postComments.listItem.columns.postedAt"),
    format: (comment: IPostComment) => {
      const date = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(new Date(comment.created_at));

      const hour = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(comment.created_at));

      return `
        <div style="color: #696974">
          <div>
            ${date}
          </div>
          <div>
            ${hour}
          </div>
        </div
    `;
    },
  },
];
