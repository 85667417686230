import { FiltersRulesGroup } from "@pasabi/ui-components";
import { generateRandomString } from "./utils";

interface FiltersObj {
  condition: "or" | "and";
  values: (FiltersValue | FiltersObj)[];
}

export interface FiltersValue {
  field: string;
  operator: string;
  value: string;
}

export const filtersToQueryString = (filters: FiltersRulesGroup): string => {
  const groupFilters = ({
    condition,
    values = [],
  }: FiltersRulesGroup): FiltersObj | undefined => {
    return values.length
      ? {
          condition,
          values: (values || [])
            .map((v) =>
              v.group
                ? (groupFilters(v.group) as FiltersObj)
                : ({
                    field: v.field,
                    operator: v.operator,
                    value: v.value,
                  } as FiltersValue)
            )
            .filter((v) => !!v),
        }
      : undefined;
  };

  return JSON.stringify(groupFilters(filters));
};

export const queryStringToFilters = (
  filters = ""
): FiltersRulesGroup | undefined => {
  const groupFilters = ({
    condition,
    values = [],
  }: FiltersObj): FiltersRulesGroup => {
    return {
      condition,
      values: (values || []).map((v) => {
        if ((v as FiltersObj).condition) {
          v = v as FiltersObj;

          return {
            id: generateRandomString(),
            field: null,
            operator: null,
            value: null,
            group: groupFilters(v),
          };
        } else {
          v = v as FiltersValue;
          return {
            id: generateRandomString(),
            field: v.field,
            operator: v.operator,
            value: v.value,
            group: null,
          };
        }
      }),
    };
  };

  if (filters) {
    try {
      return groupFilters(JSON.parse(filters));
    } catch (e) {
      console.error(e);
      return;
    }
  }
  return;
};

export const hasFilter = (
  filters: FiltersRulesGroup | undefined,
  filter: { field: string; operator: string; value: string }
): boolean => {
  return (
    !!filters &&
    !!(filters.values || []).find(
      (f) =>
        f.field === filter.field &&
        f.operator === filter.operator &&
        f.value === filter.value
    )
  );
};
