import store from "..";
import { Module, getModule } from "vuex-module-decorators";
import { IPostPhrase } from "@/types/data/IPostPhrase";
import { ListingPageStore } from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";

@Module({
  name: "postPhrases",
  store: store,
  dynamic: true,
  namespaced: true,
})
class PostPhrasesList extends ListingPageStore<IPostPhrase> {
  _apiPath = "/posts/phrases";

  get api() {
    return $api;
  }
}

export const PostPhrasesListStore = getModule(PostPhrasesList);
