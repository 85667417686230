import i18n from "@/i18n";
import {
  Activity,
  ActivityObject,
  ActivityTarget,
  Colours,
  TimelineData,
} from "@pasabi/ui-components";
import { TranslateResult } from "vue-i18n";

const entityName = (entity: ActivityTarget | ActivityObject): string => {
  if (entity._type == "posts" && entity._id) {
    return `#${entity._id}`;
  } else if (entity._type == "profiles" && entity.username) {
    return `@${entity.username}`;
  } else if (entity._type == "products" && entity.title) {
    return entity.title;
  } else {
    return entity.title || entity.name || `<${entity?._type}>#${entity?.id}`;
  }
};

export const activityIcon = (activity: Activity): string => {
  const {
    verb,
    object: { _type: objectType } = {},
    target: { _type: targetType } = {},
  } = activity;

  switch (verb) {
    case "flagged":
      return "information";
    case "tagged":
    case "untagged":
      return "bookmark";
    default:
      switch (objectType) {
        case "comments":
          return "chat";
      }

      switch (targetType) {
        case "posts":
          return "posts";
        case "profiles":
          return "watch";
        case "posts_comments":
          return "comment";
      }

      return "notifications";
  }
};

export const activityColour = (activity: Activity): typeof Colours[number] => {
  const {
    object: { _type: objectType } = {},
    target: { _type: targetType } = {},
  } = activity;

  switch (objectType) {
    case "comments":
      return "lightblue";
  }

  switch (targetType) {
    case "posts":
      return "teal";
    case "profiles":
      return "blue";
    case "posts_comments":
      return "green";
    case "clusters":
      return "orange";
  }

  return "red";
};

export const activityMessage = (
  activity: Activity
): TranslateResult | undefined => {
  const {
    object: { _type: objectType } = {},
    target: { _type: targetType } = {},
  } = activity;

  switch (objectType) {
    case "comments":
      return i18n.t("activities.info.description.comment");
    case "flags":
      return i18n.t("activities.info.description.flag");
    case "tags":
      return i18n.t("activities.info.description.tag");
    case "clusters":
      return i18n.t("activities.info.description.cluster");
  }

  switch (targetType) {
    case "posts":
      return i18n.t("activities.info.description.post");
    case "profiles":
      return i18n.t("activities.info.description.suspect");
    case "posts_comments":
      return i18n.t("activities.info.description.postComment");
    case "clusters":
      return i18n.t("activities.info.description.cluster");
  }

  return undefined;
};

export const activityTitle = (activity: Activity): TranslateResult => {
  const { object, target, actor } = activity;

  const targetName = target ? entityName(target) : undefined;
  const objectName = object ? entityName(object) : undefined;
  const actorName = actor.name;

  switch (activity.verb) {
    case "flagged":
      switch (target?._type) {
        case "posts":
          return i18n.t("activities.info.title.flagged.post", {
            actorName,
            targetName,
            objectName,
          });
        case "profiles":
          return i18n.t("activities.info.title.flagged.suspect", {
            actorName,
            targetName,
            objectName,
          });
        default:
          return i18n.t("activities.info.title.flagged.default", {
            actorName,
            targetName,
            objectName,
          });
      }
    case "tagged":
      switch (target?._type) {
        case "posts":
          return object?.tags.length
            ? i18n.t("activities.info.title.tagged.post", {
                actorName,
                targetName,
                tags: object?.tags
                  .map((t: string) => `'${t}'`)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " and $1"),
              })
            : i18n.t("activities.info.title.tagged.empty.post", {
                actorName,
                targetName,
              });
        case "profiles":
          return object?.tags.length
            ? i18n.t("activities.info.title.tagged.suspect", {
                actorName,
                targetName,
                tags: object?.tags
                  .map((t: string) => `'${t}'`)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " and $1"),
              })
            : i18n.t("activities.info.title.tagged.empty.suspect", {
                actorName,
                targetName,
              });
        case "clusters":
          return object?.tags.length
            ? i18n.t("activities.info.title.tagged.cluster", {
                actorName,
                targetName,
                tags: object?.tags
                  .map((t: string) => `'${t}'`)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " and $1"),
              })
            : i18n.t("activities.info.title.tagged.empty.cluster", {
                actorName,
                targetName,
              });
        default:
          return object?.tags.length
            ? i18n.t("activities.info.title.tagged.default", {
                actorName,
                targetName,
                tags: object?.tags
                  .map((t: string) => `'${t}'`)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " and $1"),
              })
            : i18n.t("activities.info.title.tagged.empty.default", {
                actorName,
                targetName,
                tags: object?.tags
                  .map((t: string) => `'${t}'`)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " and $1"),
              });
      }

    case "untagged":
      switch (target?._type) {
        case "posts":
          return i18n.t("activities.info.title.untagged.post", {
            actorName,
            targetName,
            tags: object?.tags
              .map((t: string) => `'${t}'`)
              .join(", ")
              .replace(/, ([^,]*)$/, " and $1"),
          });
        case "profiles":
          return i18n.t("activities.info.title.untagged.suspect", {
            actorName,
            targetName,
            tags: object?.tags
              .map((t: string) => `'${t}'`)
              .join(", ")
              .replace(/, ([^,]*)$/, " and $1"),
          });
        case "clusters":
          return i18n.t("activities.info.title.untagged.cluster", {
            actorName,
            targetName,
            tags: object?.tags
              .map((t: string) => `'${t}'`)
              .join(", ")
              .replace(/, ([^,]*)$/, " and $1"),
          });
        default:
          return i18n.t("activities.info.title.untagged.default", {
            actorName,
            targetName,
          });
      }

    case "added":
      switch (object?._type) {
        /**
         * Comments
         */
        case "comments":
          switch (target?._type) {
            case "posts":
              return i18n.t("activities.info.title.added.comment.post", {
                actorName,
                targetName,
              });
            case "profiles":
              return i18n.t("activities.info.title.added.comment.suspect", {
                actorName,
                targetName,
              });
            case "clusters":
              return i18n.t("activities.info.title.added.comment.cluster", {
                actorName,
                targetName,
              });
            default:
              return i18n.t("activities.info.title.added.comment.default", {
                actorName,
                targetName,
              });
          }
      }

      /**
       * Default
       */
      return i18n.t("activities.info.title.added.default", {
        actorName,
        targetName,
        objectName,
      });

    case "updated":
      switch (object?._type) {
        /**
         * Comments
         */
        case "comments":
          switch (target?._type) {
            case "posts":
              return i18n.t("activities.info.title.updated.comment.post", {
                actorName,
                targetName,
              });
            case "profiles":
              return i18n.t("activities.info.title.updated.comment.suspect", {
                actorName,
                targetName,
              });
            case "clusters":
              return i18n.t("activities.info.title.updated.comment.cluster", {
                actorName,
                targetName,
              });
            default:
              return i18n.t("activities.info.title.updated.comment.default", {
                actorName,
                targetName,
              });
          }

        case "clusters":
          return i18n.t("activities.info.title.updated.cluster", {
            actorName,
            objectName,
          });
      }
      /**
       * Default
       */
      return i18n.t("activities.info.title.updated.default", {
        actorName,
        targetName,
        objectName,
      });

    case "removed":
      switch (object?._type) {
        /**
         * Comments
         */
        case "comments":
          switch (target?._type) {
            case "posts":
              return i18n.t("activities.info.title.removed.comment.post", {
                actorName,
                targetName,
              });
            case "profiles":
              return i18n.t("activities.info.title.removed.comment.suspect", {
                actorName,
                targetName,
              });
            case "clusters":
              return i18n.t("activities.info.title.removed.comment.cluster", {
                actorName,
                targetName,
              });
            default:
              return i18n.t("activities.info.title.removed.comment.default", {
                actorName,
                targetName,
              });
          }
      }
      /**
       * Default
       */
      return i18n.t("activities.info.title.removed.default", {
        actorName,
        targetName,
        objectName,
      });
    default:
      return `${actor.name} ${activity.verb} ${objectName}`;
  }
};

export const activityToTimelineData = (activity: Activity): TimelineData => {
  return {
    title: activityTitle(activity).toString(),
    message: activityMessage(activity)?.toString(),
    icon: activityIcon(activity),
    colour: activityColour(activity),
    timestamp: new Date(activity.date_created),
  };
};
