import SyndicateNameColumn from "../views/syndicates/components/SyndicateNameColumn.vue";
import i18n from "@/i18n";
import { ISyndicate } from "@/types/data/ISyndicate";
import { UiTagsTableColumn } from "@pasabi/ui-patterns";
import { syndicateGraphLink } from "../lib/syndicates";

export const syndicateTableColumns = [
  {
    key: "name",
    label: i18n.t("syndicates.listItem.columns.name"),
    component: {
      name: SyndicateNameColumn,
      attr: (syndicate: ISyndicate) => ({
        syndicate,
      }),
    },
  },
  {
    key: "tags",
    sort: false,
    component: {
      name: UiTagsTableColumn,
      attr: {
        tags: (syndicate: ISyndicate) => syndicate.tags || [],
      },
    },
  },
  {
    key: "counts.profiles",
    sort: true,
    label: i18n.t("syndicates.listItem.columns.suspects"),
    format: ({ counts }: ISyndicate) => counts.profiles || 0,
  },
  {
    key: "date_formed",
    sort: true,
    label: i18n.t("syndicates.listItem.columns.dateFormed"),
    format: ({ date_formed }: ISyndicate) =>
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(new Date(date_formed)),
  },
  {
    key: "state",
    label: i18n.t("syndicates.listItem.columns.state"),
    sort: false,
    format: ({ date_inactive }: ISyndicate) =>
      `<span class="badge badge--${date_inactive ? "red" : "blue"} badge--tag">
            ${
              date_inactive
                ? i18n.t("syndicates.filters.state.options.inactive")
                : i18n.t("syndicates.filters.state.options.active")
            }
          </span>`,
  },
  {
    key: "graphLink",
    sort: false,
    label: i18n.t("syndicates.listItem.columns.graphLink"),
    component: {
      name: "UiDataTableLink",
      attr: (syndicate: ISyndicate) => ({
        to: syndicateGraphLink({ id: syndicate._id }),
        title: i18n.t("syndicates.listItem.graphLink"),
      }),
    },
  },
];
