import ChannelColumn from "@/components/tables/ChannelColumn.vue";
import i18n from "@/i18n";
import { imageProxy } from "@/lib/imageProxy";
import { postScore } from "@/lib/posts";
import { UiImage, UiPercentCircle, UiProfile } from "@pasabi/ui-components";
import { IPost } from "@/types/data/IPost";
import { UiTagsTableColumn } from "@pasabi/ui-patterns";
export const postTableColumns = [
  {
    key: "post",
    label: i18n.t("posts.listItem.columns.post"),
    component: {
      name: UiImage,
      attr: {
        class: "avatar",
        src: (post: IPost) => imageProxy(post.display_url),
        height: 42,
        width: 42,
        rounded: true,
        showOverlay: true,
      },
    },
  },
  {
    key: "posted_at",
    sort: true,
    label: i18n.t("posts.listItem.columns.postedAt"),
    format: (post: IPost) =>
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(post.posted_at)),
  },
  {
    key: "tags",
    sort: false,
    component: {
      name: UiTagsTableColumn,
      attr: {
        tags: (post: IPost) => post.tags || [],
      },
    },
  },
  {
    key: "suspect",
    label: i18n.t("posts.listItem.columns.suspect"),
    component: {
      name: UiProfile,
      attr: ({
        profile: { username, full_name: firstname, _id: id },
      }: IPost) => ({
        inline: true,
        showAvatar: false,
        to: {
          name: "SuspectDetails",
          params: { id },
        },
        profile: {
          firstname: firstname || username,
          surname: "",
          username: `@${username}`,
        },
      }),
    },
  },
  {
    key: "platform",
    label: i18n.t("posts.listItem.columns.platform"),
    component: {
      name: ChannelColumn,
      attr: ({ channel }: IPost) => ({
        channel: channel,
      }),
    },
  },
  {
    key: "hashtags_count",
    sort: true,
    label: i18n.t("posts.listItem.columns.hashtags"),
    format: (post: IPost) => post.hashtags?.length || 0,
  },
  {
    key: "mentions_count",
    sort: true,
    label: i18n.t("posts.listItem.columns.mentions"),
    format: (post: IPost) => post.mentions?.length || 0,
  },
  {
    key: "comment_count",
    sort: true,
    label: i18n.t("posts.listItem.columns.comments"),
    format: (post: IPost) => post.comment_count || 0,
  },
  {
    key: "nlp.score.score_percent",
    sort: true,
    label: i18n.t("posts.listItem.columns.suspicion"),
    component: {
      name: UiPercentCircle,
      attr: {
        size: 24,
        inline: true,
        percent: (post: IPost) => postScore(post),
      },
    },
  },
];
