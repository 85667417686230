var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"posts-map-tooltip"},[_c('div',[_vm._l((_vm.postsBySuspect),function(value,name,index){return [(index < _vm.maxItems)?_c('div',{key:name,staticClass:"row posts-map-tooltip__suspect"},[_c('div',{staticClass:"col col--valign-center col--gap"},[_c('ui-profile',{attrs:{"profile":_vm.profileBySuspect(value.suspect),"inline":"","to":{
              name: 'SuspectDetails',
              params: { id: value.suspect._id },
            }}})],1),_c('div',{staticClass:"col col--valign-center col--halign-right"},[_c('statistics',{attrs:{"value":value.postsCount,"label":_vm.$tc(
                'syndicates.posts.mapTooltip.postsCountLabel',
                value.postsCount
              ),"size":24,"colorsRanges":[{ key: 'high', min: 0 }]}})],1)]):_vm._e()]})],2),(Object.keys(_vm.postsBySuspect) > _vm.maxItems || true)?_c('div',{staticClass:"txt--center"},[_c('ui-button',{attrs:{"appearance":"link"},on:{"click":_vm.viewMoreClick}},[_vm._v(" "+_vm._s(_vm.$t("syndicates.posts.mapTooltip.viewMore"))+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }