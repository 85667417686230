import _Vue from "vue";

/**
 * This plugin aims to generate a unique uid for each component
 * It will allow to identify different instances of reusable components
 */
const UniqueId = {
  install(Vue: typeof _Vue) {
    const uidProperty = "_uid";
    const uidPrefix = "uid-";

    // Assign a unique id to each component
    let uidCounter = 0;
    Vue.mixin({
      beforeCreate() {
        uidCounter += 1;
        const uid = `${uidPrefix}${uidCounter}`;
        Object.defineProperties(this, {
          [uidProperty]: {
            get() {
              return uid;
            },
          },
        });
      },
    });

    Vue.prototype.$id = function (id = "") {
      return `${this[uidProperty]}-${id}`;
    };
  },
};

export default UniqueId;
