import store from "..";
import { Module, getModule } from "vuex-module-decorators";
import { TagsStore as BaseTagsStore } from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";

@Module({
  name: "Tags",
  store: store,
  namespaced: true,
  dynamic: true,
})
class TagsModule extends BaseTagsStore {
  get api() {
    return $api;
  }
}

export const TagsStore = getModule(TagsModule);
