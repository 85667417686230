import store from "..";
import { Module, getModule } from "vuex-module-decorators";
import { ISuspectSummary } from "@/types/data/ISuspect";
import { IListStoreAssociations, ListingPageStore } from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";

@Module({
  name: "SuspectsList",
  store: store,
  dynamic: true,
  namespaced: true,
})
class SuspectsList extends ListingPageStore<ISuspectSummary> {
  _apiPath = "/profiles";

  _associations: IListStoreAssociations = {
    filters: {
      _apiPath: "filters",
    },
    model: {
      _apiPath: "model",
    },
  };
  get api() {
    return $api;
  }
}

export const SuspectsListStore = getModule(SuspectsList);
