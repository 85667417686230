import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { mergeDeep } from "./lib/utils";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const counterfeitLocales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const patternsLocales = require.context(
    "@pasabi/ui-patterns/dist/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const blossomLocales = require.context(
    "@pasabi/ui-blossom/dist/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const chartsLocales = require.context(
    "@pasabi/ui-charts/dist/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: LocaleMessages = {};

  [patternsLocales, blossomLocales, chartsLocales, counterfeitLocales].forEach(
    (locale) => {
      locale.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const match = matched[1];
          messages[match] = mergeDeep(messages[match] || {}, locale(key));
        }
      });
    }
  );

  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
