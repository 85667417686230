import i18n from "@/i18n";
import { dateDaysDiff } from "@/lib/utils";
import { IPostLanguage } from "@/types/data/IPostLanguage";
import { localeNameByCode } from "../lib/locales";

export const languagesTableColumns = [
  {
    key: "name",
    label: i18n.t("languages.listItem.columns.name"),
    format: (language: IPostLanguage) =>
      localeNameByCode(language.name) ||
      localeNameByCode(language._id) ||
      language.name ||
      language._id,
  },
  {
    key: "last_post",
    sort: true,
    label: i18n.t("languages.listItem.columns.lastPost"),
    format: (language: IPostLanguage) => {
      if (language.last_post) {
        return i18n.tc("days-ago", dateDaysDiff(new Date(language.last_post)));
      }
      return "n/a";
    },
  },
  {
    key: "count",
    sort: true,
    label: i18n.t("languages.listItem.columns.noPosts"),
  },
];
