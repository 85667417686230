import store from "../index";
import { Module, getModule } from "vuex-module-decorators";
import { DetailsPageStore } from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";
import { IDetailsStoreAssociations } from "@pasabi/ui-patterns/types/interfaces";
import { IGroupSuspect } from "../../types/data/IGroupSuspect";

@Module({
  name: "GroupSuspectDetails",
  store: store,
  dynamic: true,
  namespaced: true,
})
class GroupSuspectDetails extends DetailsPageStore<IGroupSuspect> {
  _apiPath = "/group_profiles";
  _associations: IDetailsStoreAssociations = {
    posts: {
      _apiPath: "posts",
    },
    postsFilters: {
      _apiPath: "posts/filters",
    },
    postsByPostedAt: {
      _apiPath: "posts/group/posted_at",
    },
  };

  get api() {
    return $api;
  }

  get suspectName() {
    return (suspectId: string) => {
      const suspect = this.item(suspectId);

      if (suspect) {
        return suspect.full_name || suspect.username;
      }
      return "";
    };
  }
}

export const GroupSuspectDetailsStore = getModule(GroupSuspectDetails);
