import store from "../index";
import { AuthStore as BaseAuthStore } from "@pasabi/ui-patterns";
import { Module, getModule } from "vuex-module-decorators";
import { $api, $unauthenticatedApi } from "../../services/api/api";

@Module({ dynamic: true, name: "Auth", store: store, namespaced: true })
class Auth extends BaseAuthStore {
  get api() {
    return $unauthenticatedApi;
  }

  get authenticatedApi() {
    return $api;
  }
}

export const AuthStore = getModule(Auth);
