import i18n from "@/i18n";
import { FiltersRuleOption } from "@pasabi/ui-components";

export const suspectsFiltersOptions = ({
  tags = [],
  channels = [],
}: {
  tags: string[];
  channels: string[];
}): FiltersRuleOption[] =>
  [
    {
      name: i18n.t("suspects.filters.name").toString(),
      value: "full_name",
      type: "text",
      options: [],
    },
    {
      name: i18n.t("suspects.filters.username").toString(),
      value: "username",
      type: "text",
      options: [],
    },
    tags.length
      ? {
          name: i18n.t("suspects.filters.tags").toString(),
          value: "tags",
          type: "select[]",
          options: (tags || []).map((tag) => ({
            name: tag,
            value: tag,
          })),
        }
      : undefined,
    {
      name: i18n.t("suspects.filters.channel").toString(),
      value: "channel",
      type: "select",
      options: (channels || []).map((channel) => ({
        name: channel,
        value: channel,
      })),
    },
    {
      name: i18n.t("suspects.filters.daysSinceLastPost").toString(),
      value: "days_since_last_post",
      type: "number",
      options: [],
    },
    {
      name: i18n.t("suspects.filters.noPosts").toString(),
      value: "scores.posts_count",
      type: "number",
      options: [],
    },
    {
      name: i18n.t("suspects.filters.points").toString(),
      value: "weighted_score_by_time",
      type: "number",
      options: [],
    },
  ].filter(Boolean) as FiltersRuleOption[];
