import store from "..";
import { Action, Module, getModule } from "vuex-module-decorators";
import { IPost } from "@/types/data/IPost";
import {
  IListStoreAssociations,
  IQueryParams,
  ListingPageStore,
} from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";

@Module({
  name: "posts",
  store: store,
  dynamic: true,
  namespaced: true,
})
class PostsList extends ListingPageStore<IPost> {
  _apiPath = "/posts";

  _associations: IListStoreAssociations = {
    filters: {
      _apiPath: "filters",
    },
    model: {
      _apiPath: "model",
    },
  };
  get api() {
    return $api;
  }

  @Action
  async fetchItems(args: {
    params: IQueryParams;
    [key: string]: unknown;
  }): Promise<void> {
    super.fetchItems({
      ...args,
      params: {
        ...args.params,
        populate: "profile",
      },
    });
  }
}

export const PostsListStore = getModule(PostsList);
