import store from "..";
import { Module, getModule } from "vuex-module-decorators";
import { ISyndicate } from "@/types/data/ISyndicate";
import { IListStoreAssociations, ListingPageStore } from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";

@Module({
  name: "SyndicatesList",
  store: store,
  dynamic: true,
  namespaced: true,
})
class SyndicatesList extends ListingPageStore<ISyndicate> {
  _apiPath = "/clusters";

  _associations: IListStoreAssociations = {
    filters: {
      _apiPath: "filters",
    },
    model: {
      _apiPath: "model",
    },
  };

  get api() {
    return $api;
  }
}

export const SyndicatesListStore = getModule(SyndicatesList);
