import store from "..";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { DetailsPageStore } from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";
import { IDetailsStoreAssociations } from "@pasabi/ui-patterns/types/interfaces";

@Module({
  name: "Dashboard",
  store: store,
  dynamic: true,
  namespaced: true,
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class Dashboard extends DetailsPageStore<any> {
  dashboardAfter: string | null = "days_all";
  _apiPath = "/stats";

  _associations: IDetailsStoreAssociations = {
    headlineSuspects: {
      _apiPath: "profiles/headlines",
    },
    headlinePosts: {
      _apiPath: "posts/headlines",
    },
    headlineClusters: {
      _apiPath: "clusters/headlines",
    },
    postsSuspicionTimeSeries: {
      _apiPath: "posts/time/suspicious",
    },
    postsByPlatform: {
      _apiPath: "posts/group/channel",
    },
    postsByLocation: {
      _apiPath: "posts/group/country_code",
    },
    postsByLocationMap: {
      _apiPath: "posts/group/country_code",
    },
    postsByHashtags: {
      _apiPath: "posts/group/hashtags",
    },
  };

  get api() {
    return $api;
  }

  @Mutation
  SET_DASHBOARD_AFTER(after: string | null) {
    this.dashboardAfter = after;
  }

  @Action
  setDashboardAfter(after: string | null = "days_all") {
    this.context.commit("SET_DASHBOARD_AFTER", after);
  }
}

export const DashboardStore = getModule(Dashboard);
