import i18n from "@/i18n";
import { dateDaysDiff } from "@/lib/utils";
import { IPostPhrase } from "@/types/data/IPostPhrase";

export const phrasesTableColumns = [
  {
    key: "name",
    label: i18n.t("phrases.listItem.columns.name"),
    format: (phrase: IPostPhrase) => phrase._id,
  },
  {
    key: "last_post",
    sort: true,
    label: i18n.t("phrases.listItem.columns.lastPost"),
    format: (phrase: IPostPhrase) => {
      if (phrase.last_post) {
        return i18n.tc("days-ago", dateDaysDiff(new Date(phrase.last_post)));
      }
      return "n/a";
    },
  },
  {
    key: "count",
    sort: true,
    label: i18n.t("phrases.listItem.columns.noPosts"),
  },
];
