import countriesJson from "@/configs/maps/countries.json";

export const getCountryData = (
  countryCode: string
): { latlng: number[]; name: string; country_code: string } | undefined => {
  return countriesJson.find((country) => country.country_code == countryCode);
};

export const countriesOptions = countriesJson.map((country) => ({
  name: country.name,
  value: country.country_code,
}));
