import store from "../index";
import { Module, getModule } from "vuex-module-decorators";
import { AccountStore as BaseAccountStore } from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";

@Module({ name: "Account", store: store, namespaced: true, dynamic: true })
class Account extends BaseAccountStore {
  get api() {
    return $api;
  }
}

export const AccountStore = getModule(Account);
