import store from "..";
import { NotesStore as BaseNotesStore } from "@pasabi/ui-patterns";
import { Module, getModule } from "vuex-module-decorators";
import { $api } from "../../services/api/api";

@Module({
  name: "Notes",
  store: store,
  dynamic: true,
  namespaced: true,
})
class Notes extends BaseNotesStore {
  get api() {
    return $api;
  }
}

export const NotesStore = getModule(Notes);
