<template>
  <ui-sign-in-page :auth-store="authStore" />
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { AuthStore } from "../../store/modules/Auth";
import { UiSignInPage } from "@pasabi/ui-patterns";

@Component({
  components: {
    UiSignInPage,
  },
})
export default class SignIn extends Vue {
  authStore = AuthStore;
}
</script>
