import store from "..";
import { Module, getModule } from "vuex-module-decorators";
import { IPostLanguage } from "@/types/data/IPostLanguage";
import { $api } from "../../services/api/api";
import { ListingPageStore } from "@pasabi/ui-patterns";

@Module({
  name: "postLanguages",
  store: store,
  dynamic: true,
  namespaced: true,
})
class PostLanguagesList extends ListingPageStore<IPostLanguage> {
  _apiPath = "/posts/languages";

  get api() {
    return $api;
  }
}

export const PostLanguagesListStore = getModule(PostLanguagesList);
