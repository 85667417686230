import { render, staticRenderFns } from "./SuspectSidebar.vue?vue&type=template&id=ef2eba70&scoped=true&"
import script from "./SuspectSidebar.vue?vue&type=script&lang=ts&"
export * from "./SuspectSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./SuspectSidebar.vue?vue&type=style&index=0&id=ef2eba70&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef2eba70",
  null
  
)

export default component.exports