import i18n from "@/i18n";
import { FiltersRuleOption } from "@pasabi/ui-components";
import { RawLocation } from "vue-router";

// export const syndicateSize = (
//   syndicate: ISyndicate
// ): { max: number; size?: number; label?: string } => {
//   const label = {
//     small: i18n.t("syndicates.size.small"),
//     medium: i18n.t("syndicates.size.medium"),
//     large: i18n.t("syndicates.size.large"),
//   }[syndicate.size || "small"]?.toString();

//   const size = {
//     small: 1,
//     medium: 2,
//     large: 3,
//   }[syndicate.size || "small"];

//   return {
//     max: 3,
//     size,
//     label,
//   };
// };

// export const syndicateThreatLevel = (
//   syndicate: ISyndicate
// ): { color?: string; label?: string } => {
//   const color = {
//     low: "green",
//     medium: "orange",
//     high: "red",
//   }[syndicate.threat || "low"];

//   const label = {
//     low: i18n.t("syndicates.threat.low"),
//     medium: i18n.t("syndicates.threat.medium"),
//     high: i18n.t("syndicates.threat.high"),
//   }[syndicate.threat || "low"]?.toString();

//   return {
//     color,
//     label,
//   };
// };

export const syndicatesFiltersOptions = ({
  tags = [],
}: {
  tags: string[];
}): FiltersRuleOption[] => [
  {
    name: i18n.t("syndicates.filters.name").toString(),
    value: "name",
    type: "text",
    options: [],
  },
  {
    name: i18n.t("syndicates.filters.tags").toString(),
    value: "tags",
    type: "select[]",
    options: (tags || []).map((tag) => ({
      name: tag,
      value: tag,
    })),
  },
  {
    name: i18n.t("syndicates.filters.state.label").toString(),
    value: "state",
    type: "select",
    options: [
      {
        name: i18n.t("syndicates.filters.state.options.active").toString(),
        value: "active",
      },
      {
        name: i18n.t("syndicates.filters.state.options.inactive").toString(),
        value: "inactive",
      },
    ],
  },
  {
    name: i18n.t("syndicates.filters.dateFormed.label").toString(),
    value: "date_formed",
    type: "date",
  },
];

export const syndicateGraphLink = ({ id }: { id: string }): RawLocation => ({
  name: "SyndicateGraph",
  params: {
    id,
  },
});
