import router from "../../router";
import store from "..";
import { Action, Module, getModule } from "vuex-module-decorators";
import {
  Workspace,
  WorkspacesStore as BaseWorkspacesStore,
} from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";
import { GroupsStore } from "./Groups";
import { AuthStore } from "./Auth";

@Module({
  name: "Workspaces",
  store: store,
  namespaced: true,
  dynamic: true,
})
class WorkspacesModule extends BaseWorkspacesStore {
  get api() {
    return $api;
  }

  get workspacesList(): Workspace[] {
    if (this.items.length) {
      return this.items;
    } else {
      return AuthStore.userPermissions();
    }
  }

  get currentWorkspace(): Workspace | undefined {
    return this.workspacesList.find((w) => w._id == this.currentWorkspaceId);
  }

  get workspaceById(): (id: string) => Workspace | undefined {
    return (id: string) => {
      return this.workspacesList.find((w) => w._id == id);
    };
  }

  get workspaceBySlug(): (slug: string) => Workspace | undefined {
    return (slug: string) => {
      return this.workspacesList.find((w) => w.slug == slug);
    };
  }

  get defaultCurrentWorkspace(): Workspace | undefined {
    return this.workspacesList.find((w) => w.current === true);
  }

  @Action
  async redirectToWorkspace(workspace: Workspace) {
    if (router.currentRoute.params.workspaceBySlug !== workspace.slug) {
      const curMatchesWorkspace = router.currentRoute.matched.some(
        (r) => r.meta.workspaceContext
      );

      router.push(
        curMatchesWorkspace
          ? {
              params: {
                ...router.currentRoute.params,
                workspaceSlug: workspace.slug,
              },
            }
          : {
              name: "WorkspaceHome",
              params: {
                workspaceSlug: workspace.slug,
              },
            }
      );
    }
  }

  @Action
  async setWorkspaceById({
    workspaceId,
    redirect = false,
  }: {
    workspaceId: string;
    redirect: boolean;
  }) {
    // check if workspaces were already loaded into store
    if (!this.workspacesList.length) {
      await this.context.dispatch("loadWorkspaces");
    }

    const workspace = this.workspaceById(workspaceId);

    if (workspace) {
      this.context.dispatch("setCurrentWorkspaceId", workspace._id);
      GroupsStore.setCurrentGroupId(workspace.group_id);

      if (redirect) {
        this.context.dispatch("redirectToWorkspace", workspace);
      }
    } else {
      console.error(`Invalid workspace selected (${workspaceId})`);
      router.push({
        name: "Home",
      });
    }
  }

  @Action
  async setWorkspaceBySlug({
    workspaceSlug,
    redirect = false,
  }: {
    workspaceSlug: string;
    redirect: boolean;
  }) {
    // check if workspaces were already loaded into store
    if (!this.workspacesList.length) {
      await this.context.dispatch("loadWorkspaces");
    }

    const workspace = this.workspaceBySlug(workspaceSlug);

    if (workspace) {
      this.context.dispatch("setCurrentWorkspaceId", workspace._id);
      GroupsStore.setCurrentGroupId(workspace.group_id);

      if (redirect) {
        this.context.dispatch("redirectToWorkspace", workspace);
      }
    } else {
      console.error(`Invalid workspace selected (${workspaceSlug})`);
      router.push({
        name: "Home",
      });
    }
  }
}
export const WorkspacesStore = getModule(WorkspacesModule);
