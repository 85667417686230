import store from "../index";
import { Module, getModule } from "vuex-module-decorators";
import { ISyndicate } from "@/types/data/ISyndicate";
import {
  DetailsPageStore,
  IDetailsStoreAssociations,
} from "@pasabi/ui-patterns";
import { $api } from "../../services/api/api";

@Module({
  name: "SyndicateDetails",
  store: store,
  dynamic: true,
  namespaced: true,
})
class SyndicateDetails extends DetailsPageStore<ISyndicate> {
  _apiPath = "/clusters";
  _associations: IDetailsStoreAssociations = {
    posts: {
      _apiPath: "posts",
    },
    postsFilters: {
      _apiPath: "posts/filters",
    },
    suspects: {
      _apiPath: "profiles",
    },
    suspectsFilters: {
      _apiPath: "profiles/filters",
    },
  };

  get api() {
    return $api;
  }

  get syndicateName() {
    return (syndicateId: string) => {
      const syndicate = this.item(syndicateId);

      if (syndicate) {
        return syndicate.name;
      }
      return "";
    };
  }
}

export const SyndicateDetailsStore = getModule(SyndicateDetails);
