import i18n from "@/i18n";
import { IPost } from "@/types/data/IPost";
import { FiltersRuleOption } from "@pasabi/ui-components";
import { countriesOptions } from "./countries";

export const postScore = (post?: IPost | null): number => {
  if (post?.nlp?.score) {
    // WARN
    // it's necessary to cut off the value to 100% because there were some
    // strange values with over 100%
    return Math.min(100, Math.round(post.nlp.score.score_percent * 100));
  }
  return 0;
};

export const postsFiltersOptions = ({
  tags = [],
  channels = [],
  countries = [],
}: {
  tags: string[];
  channels: string[];
  countries: string[];
}): FiltersRuleOption[] => [
  {
    name: i18n.t("posts.filters.suspectName").toString(),
    value: "owner.full_name",
    type: "text",
    options: [],
  },
  {
    name: i18n.t("posts.filters.suspectUsername").toString(),
    value: "owner.username",
    type: "text",
    options: [],
    valueTransformer: (v: string, action: string) => {
      if (action == "out") {
        const result = v;
        return result.replace(/^@/, "");
      }
      return v;
    },
  },
  {
    name: i18n.t("posts.filters.postedAt").toString(),
    value: "posted_at",
    type: "date",
    options: [],
  },
  {
    name: i18n.t("posts.filters.tags").toString(),
    value: "tags",
    type: "select[]",
    options: (tags || []).map((tag) => ({
      name: tag,
      value: tag,
    })),
  },
  {
    name: i18n.t("suspects.filters.channel").toString(),
    value: "channel",
    type: "select",
    options: (channels || []).map((channel) => ({
      name: channel,
      value: channel,
    })),
  },
  {
    name: i18n.t("posts.filters.noHashtags").toString(),
    value: "hashtags_count",
    type: "number",
    options: [],
  },
  {
    name: i18n.t("posts.filters.noMentions").toString(),
    value: "mentions_count",
    type: "number",
    options: [],
  },
  {
    name: i18n.t("posts.filters.noComments").toString(),
    value: "comment_count",
    type: "number",
    options: [],
  },
  {
    name: i18n.t("posts.filters.suspiciousLevel").toString(),
    value: "nlp.score.score_percent",
    type: "number",
    options: [],
    valueProps: {
      min: 0,
      max: 100,
      step: 1,
    },
    valueTransformer: (v: number, action: string) => {
      if (action == "in") {
        return (v * 100).toFixed(0);
      } else {
        return parseFloat((v / 100).toFixed(2));
      }
    },
  },
  {
    name: i18n.t("posts.filters.countryCode").toString(),
    value: "address.country",
    type: "select",
    options: countriesOptions.filter((c) => countries.includes(c.value)),
  },
];
